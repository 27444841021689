import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Seo from '../components/SEO';
import Layout from '../components/Layout';
import Breadcrumbs from '../components/Breadcrumbs';
import Button from '../components/Button';
import * as style from '../assets/style/pages/modular-furniture-page.module.scss';

const ModularFurniturePage = ({ location, data }) => {
  const crumbs = [
    { title: 'Strona główna', url: '/' },
    { title: 'System mebli modułowych', url: '/system-mebli-modulowych' }
  ];
  const modularFurniturePage = () => {
    const pageData = data.modularFurniture;
    return {
      title: pageData.title.value,
      mainImage: pageData.mainImage.value,
      description: pageData.description.value,
      files: pageData.files.value.map(({value}) => ({
        file: value.file.value,
        filename: value.filename.value
      })),
      colorsSection: {
        title: pageData.colorsSectionTitle.value,
        subtitle: pageData.colorsSectionSubtitle.value,
        image: pageData.colorsSectionImage.value
      },
      guideSection: {
        title: pageData.guideSectionTitle.value,
        subtitle: pageData.guideSectionSubtitle.value,
        steps: pageData.guideSectionSteps.value.map(({value}) => ({
          title: value.title.value,
          description: value.description.value,
        })),
        images: pageData.guideSectionImages.value.map(({ value }) => value)
      },
      catalogSection: {
        title: pageData.catalogSectionTitle.value,
        images: pageData.catalogSectionImages.value.map(({ value }) => value)
      }
    }
  }

  return (
    <Layout>
      <Seo title="System mebli modułowych">
        <script type="application/ld+json">
          {`{
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement":
            [
              ${crumbs.map(({ title, url }, i) => `
                {
                  "@type": "ListItem",
                  "position": ${i + 1},
                  "item": {
                    "@type": "WebPage",
                    "@id": "${location.origin + url}",
                    "name": "${title}"
                  }
                }
              `)}
            ]
          }`}
        </script>
      </Seo>
      <Breadcrumbs crumbs={crumbs} />
      <section className={`section ${style.modularFurniture}`}>
        <div className="container">
          <GatsbyImage image={getImage(modularFurniturePage().mainImage)} alt={modularFurniturePage().title} />
          <div className={`${style.modularFurniture__content} col-12 col-lg-10`}>
            <h1>{modularFurniturePage().title}</h1>
            <div dangerouslySetInnerHTML={{ __html: modularFurniturePage().description }}></div>
            <div className="row">
              {
                modularFurniturePage().files.map(({ file, filename }, i) => (
                  <div key={`file-${i}`} className="col-auto">
                    <Button as="a" href={file} variant='border' color='#d72226' contrast='white' download>
                      { filename }
                    </Button>
                  </div>
                ))
              }
              <div className="col-auto">
                <Button as={Link} to="/kontakt" variant='primary' color='white'>
                  Wyślij e-mail z zapytaniem
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={`section ${style.colors}`}>
        <div className="container">
          <h2 className="section__title section__title--center">
            <span>{modularFurniturePage().colorsSection.subtitle}</span>
            {modularFurniturePage().colorsSection.title}
          </h2>
          <div className={`${style.colors__content} col-12 col-lg-8`}>
            <GatsbyImage image={getImage(modularFurniturePage().colorsSection.image)} alt={modularFurniturePage().colorsSection.title} />
          </div>
        </div>
      </section>
      <section className={`section ${style.guide}`}>
        <div className="container">
          <h2 className="section__title section__title--center">
            <span>{modularFurniturePage().guideSection.subtitle}</span>
            {modularFurniturePage().guideSection.title}
          </h2>
          <div className="row">
            {
              modularFurniturePage().guideSection.steps.map((step, i) => (
                <div key={`step-${i}`} className="col-12 col-lg-4">
                  <div className={style.guide__step}>
                    <span>{i + 1}.</span>
                    <strong>{step.title}</strong>
                    <div dangerouslySetInnerHTML={{__html: step.description}}></div>
                  </div>
                </div>
              ))
            }
          </div>
          <div className="row">
            {
              modularFurniturePage().guideSection.images.map((image, i) => (
                <div key={image.id} className="col-12 col-lg-4">
                  <GatsbyImage image={getImage(image)} alt="System mebli modułowych" />
                </div>
              ))
            }
          </div>
        </div>
      </section>
      <section className={`section ${style.catalog}`}>
        <div className="container">
          <h2 className="section__title section__title--center">
            {modularFurniturePage().catalogSection.title}
          </h2>
          {
            modularFurniturePage().catalogSection.images.map((image, i) => (
              <GatsbyImage key={image.id} image={getImage(image)} alt={`Katalog systemu mebli modułowych ${i}`} />
            ))
          }
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    modularFurniture: cockpitModularFurniture {
      title {
        value
      }
      mainImage {
        value {
          childImageSharp {
            gatsbyImageData(
              width: 1110,
              quality: 100,
              placeholder: BLURRED,
              formats: WEBP
            )
          }
        }
      }
      description {
        value
      }
      files {
        value {
          value {
            file {
              value
            }
            filename {
              value
            }
          }
        }
      }
      colorsSectionTitle {
        value
      }
      colorsSectionSubtitle {
        value
      }
      colorsSectionImage {
        value {
          childImageSharp {
            gatsbyImageData(
              width: 1110,
              quality: 100,
              placeholder: BLURRED,
              formats: WEBP
            )
          }
        }
      }
      guideSectionTitle {
        value
      }
      guideSectionSubtitle {
        value
      }
      guideSectionSteps {
        value {
          value {
            title {
              value
            }
            description {
              value
            }
          }
        }
      }
      guideSectionImages {
        value {
          value {
            id
            childImageSharp {
              gatsbyImageData(
                width: 690,
                height: 450,
                quality: 100,
                placeholder: BLURRED,
                formats: WEBP
                transformOptions: {cropFocus: CENTER, fit: COVER}
              )
            }
          }
        }
      }
      catalogSectionTitle {
        value
      }
      catalogSectionImages {
        value {
          value {
            id
            childImageSharp {
              gatsbyImageData(
                width: 1110,
                quality: 100,
                placeholder: BLURRED,
                formats: WEBP
              )
            }
          }
        }
      }
    }
  }
`;

export default ModularFurniturePage;
